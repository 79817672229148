<template>
  <div class="">
    <vue-data-table
      :cols="[
        {
          header: 'Name',
          value: 'name',
        },
        {
          header: 'Image',
          value: 'image'
        },
        {
          header: 'Video',
          value: 'default_video'
        },
        {
          header: 'Performance Specs?',
          value: 'specs'
        },
        {
          header: 'Public?',
          value: 'public'
        },
        {
          header: 'Added At',
          value: 'created_at',
          sort: true
        },
        {
          header: 'Last Modified',
          value: 'updated_at',
          sort: true
        },
        {
          header: 'Actions',
          value: 'actions'
        }
      ]"
      path="metadata"
      :request-params="{
        withImages: true,
        type: 'game'
      }"
    >
      <template #header-left>
        <vue-button
          :icon="['fas', 'plus-circle']"
          @click="$router.push({ name: 'games.new' })"
        >
          Add Game
        </vue-button>
      </template>
      <template v-slot:item.name="row">
        {{ row.value.name }}
      </template>
      <template v-slot:item.image="row">
        <img
          v-if="row.images && row.images.game_square"
          :src="row.images.game_square.icon"
          class="rounded"
        />
        <font-awesome-icon
          v-else
          :icon="['fas', 'times']"
          class="text-red-700"
          fixed-width
        />
      </template>
      <template v-slot:item.default_video="row">
        <a v-if="row.value && row.value.default_video" :href="`https://www.youtube.com/watch?v=${row.value.default_video}`" target="_blank">
          <font-awesome-icon :icon="['fas', 'video']" fixed-width />
        </a>
        <font-awesome-icon
          v-else
          :icon="['fas', 'times']"
          class="text-red-700"
          fixed-width
        />
      </template>
      <template v-slot:item.specs="row">
        <font-awesome-icon
          v-if="row.value && row.value.specs && row.value.specs.length > 0"
          :icon="['fas', 'check']"
          class="text-green-700"
          fixed-width
        />
        <font-awesome-icon
          v-else
          :icon="['fas', 'times']"
          class="text-red-700"
          fixed-width
        />
      </template>
      <template v-slot:item.public="row">
        <font-awesome-icon
          v-if="row.value && row.value.public"
          :icon="['fas', 'check']"
          class="text-green-700"
          fixed-width
        />
        <font-awesome-icon
          v-else
          :icon="['fas', 'times']"
          class="text-red-700"
          fixed-width
        />
      </template>
      <template v-slot:item.created_at="row">
        {{ $momentDateTimeHuman(row.created_at) }}
      </template>
      <template v-slot:item.updated_at="row">
        {{ $momentDateTimeHuman(row.updated_at) }}
      </template>
      <template v-slot:item.actions="row">
        <vue-link
          :to="{ path: `/games/${row.id}` }"
          :icon="['fas', 'edit']"
          :aria-label="`Edit Game: ${row.name}`"
          text
          sm
          class="inline-flex cursor-pointer"
        />
      </template>
    </vue-data-table>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'Games'
}
</script>
